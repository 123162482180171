import React, { Component } from 'react';
import Input from '../Global/Input';
import arrowWhite from '../../assets/icons/arrowdown-white.svg';
import loupe from '../../assets/icons/loupeGray.svg';
import BlogCategory from './BlogCategory';
import { isMobile } from 'react-device-detect';

export default class BlogCategories extends Component {
    state = {
        hideSearch: false
    }
    render() {
        const { categories, setActiveCategory, activeCategory, loading, search, onChange } = this.props
        const { hideSearch } = this.state;
        return (
            <div style={{ display: loading ? 'none' : 'flex', animation: loading ? '' : 'drawerTop .4s linear backwards' }} className={`blog-categories ${hideSearch ? 'blog-categories--hidden' : ''}`}>
                {!isMobile && categories?.length ?
                categories.map(category => {
                    return <BlogCategory 
                    setCategory={setActiveCategory} 
                    category={category?.title} 
                    activeCategory={activeCategory} 
                    key={category?._id} />
                }) : null}

                
                <Input onKeyDown={this.handleBlur} id="search" name="search" changeHandler={onChange} value={search} placeholder="Pretraga" icon={loupe} iconClasses={`blog-categories__input__icon ${isMobile ? 'blog-categories__input__icon--mobile' : ''}`} labelClasses="blog-categories__input__label" inputClasses={`blog-categories__input ${isMobile ? 'blog-categories__input--mobile' : ''}`} containerClasses={`blog-categories__input__container ${isMobile ? 'blog-categories__input__container--mobile' : ''}`} />
                <div onClick={this.toggleSearch} className={`blog-categories__arrow ${hideSearch ? 'blog-categories__arrow--hidden' : ''}`}>
                    <img src={arrowWhite} alt="arrow-down" className={`blog-categories__arrow__image ${hideSearch ? 'blog-categories__arrow__image--hidden' : ''}`} />
                </div>
            </div>
        );
    }
    toggleSearch = () => {
        this.setState({
            hideSearch: !this.state.hideSearch
        })
    }
    handleBlur = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur()
        }
    }
}