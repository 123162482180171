import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

import BlogCard from '../Global/BlogCard';
import BlogTab from './BlogTab';
import ImageBlogCard from './ImageBlogCard';
import LongBlogCard from './LongBlogCard';

export default class BlogContainer extends Component {
    state = {
        activeTab: null
    }
    render() {
        const { hasSidePanel, tabs, isMainPanel, category, newest, popular, suggested, posts } = this.props;
        const { activeTab } = this.state;
        return (
            <div className="blog-container">
                <div style={{width: hasSidePanel ? '80%' : '100%'}} className="blog-container__posts">
                    <div className="blog-container__title">
                        <h3 className="blog-container__title__main">
                            {isMainPanel ? 'Najnovije' : category}
                        </h3>
                    </div>
                    <div className="blog-container__cards">
                        { isMainPanel ? 
                        newest?.map((post, i) => {
                            return i < 6 ? (isMobile ? <ImageBlogCard post={post} key={post._id} /> : <BlogCard key={post?._id} post={post} />) : null;
                        }) :
                        posts?.map((post, i) => {
                            return i < 6 ? (isMobile ? <ImageBlogCard post={post} key={post._id} /> : <BlogCard key={post?._id} post={post} />) : null;
                        })}
                    </div>
                </div>

                {hasSidePanel ?
                    <div className="blog-container__related">
                        <div className="blog-container__related__tabs">
                            {tabs?.length ? tabs.map(tab => {
                                return <BlogTab 
                                        tab={tab}
                                        tabName={this.getTabName(tab)} 
                                        key={tab} 
                                        activeTab={this.state.activeTab} 
                                        setActiveTab={this.setActiveTab} />
                                }) 
                            : null}
                        </div>
                        <div className="blog-container__related__posts">
                            {activeTab === 'newest' || !activeTab ?
                                newest?.map((post, i) => {
                                    return i >= 6 ? <LongBlogCard post={post} key={post?._id} /> : null;
                                }) : null}
                            {activeTab === 'popular' || !isMainPanel ?
                                popular?.map((post, i) => {
                                    return i >= 6 ? <LongBlogCard post={post} key={post?._id} /> : null;
                                }) : null}
                            {activeTab === 'suggested' ?
                                suggested?.map(post => {
                                    return <LongBlogCard post={post} key={post?._id} />
                                }) : null}
                            
                        </div>
                    </div>
                : null}
            </div>
        );
    }

    getTabName = (tab) => {
        switch (tab) {
            case 'newest': {
                return 'Najnovije';
            }
            case 'popular': {
                return 'Popularno';
            }
            default: {
                return 'Preporučeno';
            }
        }
    }
    setActiveTab = (tab) => {

        this.setState({
            activeTab: tab
        })
    }
}