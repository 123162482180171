import React from 'react';
import moment from 'moment';

import { createImageUrl } from '../../helpers/commonHelpers';

import placeholder from '../../assets/images/placeholder.png';
import { Link } from 'react-router-dom';

const ImageBlogCard = ({ post }) => {
    return (
        <Link to={{ pathname: `/post/${post?._id}`, state: post?._id}}>
        <div className="image-blog-card">
            
                <div className="image-blog-card__left">
                    <img className="image-blog-card__image" src={post?.main_image ? createImageUrl(post?.main_image) : placeholder} alt="blog" />
                </div>
                <div className="image-blog-card__right">
                    <h1 className="image-blog-card__category">
                        {post?.category}
                    </h1>
                    <p className="image-blog-card__title">
                        {post?.title}
                    </p>
                    <p className="image-blog-card__muni">
                        {post?.municipalityName} &bull; {post?.mayorName}
                    </p>
                    <p className="image-blog-card__description">
                        {post?.description}
                    </p>
                    <p className="image-blog-card__date">
                        {moment(post?.publishedAt).format('DD.MM.YYYY.')}
                    </p>
                </div>
            
        </div>
        </Link>
    );
}

export default ImageBlogCard;