import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import BlogCategories from './BlogCategories';
import BlogContainer from './BlogContainer';

import { fetchMainBlogPosts, setActiveCategory, clearBlogState, getSearchPosts } from '../../store/actions/blogActions';
import { isObjectEmpty } from '../../helpers/commonHelpers';

import BlogPostList from './BlogPostsList';
import loader from '../../assets/animations/loader-b.svg';
import { Helmet } from 'react-helmet';

class BlogPage extends Component {
    state = {
        search: ''
    }
    componentDidMount() {
        this.props.fetchMainBlogPosts();
    }
    componentWillUnmount(){
        this.props.clearBlogState();
    }
    componentDidUpdate(prevProps){
        if(prevProps.activeCategory !== this.props.activeCategory){
            document.getElementById('content').scrollTop = 0;
        }
        if (prevProps.fetchingMainPosts && !this.props.fetchingMainPosts){
            window.prerenderReady = true;
        }
    }
    
    render() {
        const { search } = this.state;
        const { categories, main, posts, activeCategory, categoryPosts, fetchingMainPosts, fetchingSearchPosts, fetchingActiveCategoryPosts, searchPosts } = this.props;
        const postCategories = Object.keys(posts);
        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:image" content="/2-05.png" />
                </Helmet>
                <BlogCategories search={search} onChange={this.setSearchTerm} loading={fetchingMainPosts} activeCategory={activeCategory} setActiveCategory={this.props.setActiveCategory} categories={categories} />

                <div className="blog-page">
                    {fetchingMainPosts ? <img src={loader} alt="loader" className="blog-page__loading" /> :
                    <React.Fragment>
                        {search ?
                        <React.Fragment>
                            {
                                fetchingSearchPosts ?
                                    <img src={loader} alt="loader" className="blog-page__loading" /> :
                                <BlogPostList isSearch={true} categoryPosts={searchPosts} />
                            }
                        </React.Fragment> : 
                        <React.Fragment>
                            {activeCategory ?
                                (fetchingActiveCategoryPosts ?
                                    <img src={loader} alt="loader" className="blog-page__loading" /> :
                                    <BlogPostList categoryPosts={categoryPosts} />) :
                                <React.Fragment>
                                    {main && !isObjectEmpty(main) ?
                                        <BlogContainer
                                            isMainPanel={true}
                                            tabs={main.tabs}
                                            hasSidePanel={main.hasSidePanel}
                                            newest={main.newest}
                                            popular={main.popular}
                                            suggested={main.suggested} />
                                        : null}
                                    {postCategories?.length ?
                                        postCategories.map(category => {
                                            return <BlogContainer
                                                key={category}
                                                isMainPanel={false}
                                                tabs={posts[category].tabs}
                                                hasSidePanel={posts[category].hasSidePanel}
                                                posts={posts[category].posts}
                                                popular={posts[category].popular}
                                                newest={posts[category].newest}
                                                suggested={posts[category].suggested}
                                                category={posts[category].category} />
                                        }) : null}

                                    {fetchingMainPosts ?
                                        <BlogContainer

                                            isMainPanel={true}
                                        />
                                        : null}
                                </React.Fragment>}
                        </React.Fragment>}
                        
                    </React.Fragment>}
                    
                </div>
            </React.Fragment>
        );
    }

    fetchSearchPosts = debounce(async () => {
        this.props.getSearchPosts(this.state.search)
    }, 200)

    setSearchTerm = (event) => {
        this.setState({
            search: event.target.value
        }, () => {
            this.fetchSearchPosts();
        })
    }
}
const mapStateToProps = (state) => {
    return {
        main: state.blog.main,
        activeCategory: state.blog.activeCategory,
        categories: state.blog.categories,
        posts: state.blog.posts,
        categoryPosts: state.blog.categoryPosts,
        fetchingMainPosts: state.blog.fetchingMainPosts,
        fetchingActiveCategoryPosts: state.blog.fetchingActiveCategoryPosts,
        searchPosts: state.blog.searchPosts,
        fetchingSearchPosts: state.blog.fetchingSearchPosts
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchMainBlogPosts: () => { dispatch(fetchMainBlogPosts()) },
        setActiveCategory: (category) => { dispatch(setActiveCategory(category)) },
        clearBlogState: () => { dispatch(clearBlogState()) },
        getSearchPosts: (search) => { dispatch(getSearchPosts(search)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);