import { config } from "../config";

export const isObjectEmpty = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object
}

export const createImageUrl = (url) => {
    if(!url) return;
    url = url.replace('\\', '/');
    return `${config.imageUrl}/${url}`
}

export const createFullName = (user) => {
    return `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`;
}

export const isEqual = (firstObject, secondObject) => {
    return JSON.stringify(firstObject) === JSON.stringify(secondObject);
}

export const getIdsFromObjectsArray = entities => {
    return entities.map(entity => entity._id)
}

export const getEntityName = (entity) => {
    if(!entity) return 'Nepoznato';
    switch(entity) {
        case 'RS': {
            return 'Republika Srpska';
        }
        case 'DB': {
            return 'Distrikt Brčko';
        }
        default: {
            return 'Federacija BiH';
        }
    }
}