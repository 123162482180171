import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import ImageBlogCard from './ImageBlogCard';

import { getMorePosts, setBlogPage } from '../../store/actions/blogActions';

class BlogPostList extends Component {
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true)
    }

    componentDidUpdate(prevProps) {
        const { type, filters } = this.props;

        if (prevProps.type !== type) {
            this.props.getDogs(filters)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }


    handleScroll = debounce(async () => {
        const wrappedElement = document.getElementById('posts-container');
        const { categoryItems, categoryPage, categoryPosts } = this.props;
        if (this.isBottom(wrappedElement) && categoryItems && categoryPosts?.length && categoryItems !== categoryPosts?.length) {
            await this.props.setBlogPage(categoryPage + 1);
            this.props.getMorePosts();
        }
    }, 200)

    render() {
        const { categoryPosts, isSearch } = this.props;
        return (
            <div id={`posts-container`} className="blog-page__category">
                {categoryPosts?.length ? categoryPosts.map(post => {
                    return <ImageBlogCard post={post} key={post._id} />
                }) : <h1 className="text-center">
                        Ne postoje članci {isSearch ? 'koji odgovaraju vašoj pretrazi' : 'u ovoj kategoriji'}
                    </h1>}
            </div>
        );

    }
}
const setStateToProps = (state) => {
    return {
        categoryItems: state.blog.categoryItems,
        categoryPage: state.blog.categoryPage,
    }
}
const setDispatchToProps = (dispatch) => {
    return {
        setBlogPage: (page) => { dispatch(setBlogPage(page)) },
        getMorePosts: () => { dispatch(getMorePosts()) }
    }
}
export default connect(setStateToProps, setDispatchToProps)(BlogPostList);