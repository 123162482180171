import React from 'react';

const BlogCategory = ({ setCategory, category, activeCategory}) => {
    return (
        <div 
        onClick={() => { setCategory(category) }} 
        className={`blog-categories__category ${category === activeCategory ? 'blog-categories__category--active' : ''}`}>
            {category}
        </div>
    );
}

export default BlogCategory;