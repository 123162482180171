import React from 'react';

const BlogTab = ({ setActiveTab, activeTab, tab, tabName }) => {
    return (
        <div 
        onClick={() => { setActiveTab(tab) }} 
        className={`blog-container__related__tab ${activeTab === tab ? 'blog-container__related__tab--active' : ''}`}>
            {tabName}
        </div>
    )
}

export default BlogTab;