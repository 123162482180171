import React from 'react';
import { Link } from 'react-router-dom';

const LongBlogCard = ({ post }) => {
    return (
        <Link to={{ pathname: `/post/${post?._id}`, state: post?._id }}>
        <div className="blog-container__related__post">
            <h1 className="blog-container__related__post__category">
                {post.category}
            </h1>
            <p className="blog-container__related__post__title">
                {post.title}
            </p>
        </div>
        </Link>
    );
}

export default LongBlogCard;