import React from 'react';

import clock from '../../assets/icons/clock.svg';
import placeholder from '../../assets/images/placeholder.png';
import { createImageUrl } from '../../helpers/commonHelpers';
import moment from 'moment';
import government from '../../assets/icons/goverment.svg';
import { Link } from "react-router-dom";


export default function BlogCard({ post }) {
    
    return (
        
            <div className="blog-card">
            <Link to={{ pathname: `/post/${post._id}`, state: post._id }} >
                <div className="blog-card__upper">
                    <div className="blog-card__overlay" />
                    <img src={post?.main_image ? createImageUrl(post.main_image) : placeholder} alt="title" className="blog-card__img" />
                    <div className="blog-card__top-info">
                        <h1 className="blog-card__top-info__category white-text">
                            {post.category ?? ''}
                        </h1>
                        <div className="blog-card__top-info__post-time">
                            <img className="blog-card__top-info__post-time__icon" src={clock} alt="clock" />
                            {moment(post?.publishedAt).format('DD.MM.YYYY.')}
                        </div>
                    </div>
                    <div className="blog-card__title">
                        {post.title ?? ''}
                    </div>
                    <div className="blog-card__description">
                        {post.description ?? ''}
                    </div>
                </div>
                <div className="blog-card__lower">
                    <h1 className="blog-card__author">
                        <img src={government} alt="government" /> {post?.municipalityName}
                    </h1>
                    <h2 className="blog-card__author">
                        {post?.mayorName}
                    </h2>
                </div>
            </Link>
            </div>
        
    );
}